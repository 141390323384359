<template>
  <v-card id="customer-form">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-form
      ref="CustomerForm"
      v-model="valid"
      lazy-validation
    >
      <v-card-title>Customer Form</v-card-title>
      <div style="padding-left:15px">
        <v-row>
          <v-col>
            <img
              v-if="customer.isModifingCustomer && CustomerForm.logo"
              id="customer-logo"
              :src="file_transfer.downloadLink"
            >
          </v-col>
        </v-row>
      </div>
      <v-card-text>
        <v-text-field
          v-model="CustomerForm.crm_id"
          label="ID CRM"
          required
          :disabled="crmIdDisabled"
          :rules="crm_id_rules"
          :counter="20"
        />

        <v-text-field
          v-model="CustomerForm.ragione_sociale"
          label="Ragione Sociale"
          required
          :rules="ragione_sociale_rules"
          :counter="255"
        />

        <v-text-field
          v-model="CustomerForm.c_description"
          label="Customer Description"
          :rules="c_description_rules"
          :counter="255"
        />
        <v-text-field
          v-model="CustomerForm.latitude"
          label="Latitude"
          :rules="geo_rules"
          :counter="20"
        />
        <v-text-field
          v-model="CustomerForm.longitude"
          label="Longitude"
          :rules="geo_rules"
          :counter="20"
        />
        <v-select
          v-model="CustomerForm.zoom"
          :items="zoomValues"
          label="Zoom"
          dense
        />
        <v-select
          v-model="CustomerForm.map_style"
          :items="mapStyles"
          label="Map Style"
          dense
        />
        <div
          v-if="
            CustomerForm.logo != '' &&
              CustomerForm.logo != undefined
          "
          class="mb-2 ml-8"
        >
          <v-chip
            v-if="customer.isModifingCustomer"
            small
            label
            color="color_green"
            style="color:white"
          >
            <i>Existing uploaded file {{ splitBucketS3Path }}</i>
          </v-chip>
        </div>
        <v-file-input
          v-model="image"
          accept=".png,.gif,.jpeg,.jpg"
          class="mt-4"
          label="Insert Customer Logo"
          prepend-inner-icon="mdi-image"
          outlined
          truncate-length="50"
          @change="preview_image"
        />
        <div
          v-if="imageError"
          style="color:red"
          class="imageError"
        >
          {{ imageError }}
        </div>
        <div v-if="url && seeImage">
          <v-row class="mt-3">
            <v-spacer />
            <img :src="url">
            <v-spacer />
          </v-row>
          <v-row class="mt-5">
            <v-spacer />
            <v-btn
              dark
              outlined
              color="color_green"
              width="155"
              @click="upload_image()"
            >
              Apply logo
            </v-btn>
            <v-spacer />
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          class="mr-4"
          @click.prevent="clear"
        >
          Clear
        </v-btn>
        <v-spacer />
        <v-btn
          dark
          color="color_green"
          class="mr-4"
          :disabled="checkForm()"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, manageLenghtError, getRegExp } from '@/formRules.js';
import { MAP_STYLES, S3_BUCKETS, log } from '@/constants.js';

export default {
  data() {
    return {
      overlay: false,
      valid: true,
      seeImage: false,
      url: '',
      image: null,
      imageError: '',
      height_img: '',
      width_img: '',
      downloadFile: true,
      CustomerForm: {
        crm_id: '',
        ragione_sociale: '',
        c_description: '',
        latitude: '',
        longitude: '',
        zoom: 0,
        map_style: 'classic',
        logo: '',
        state: true
      },
      zoomValues: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
      mapStyles: MAP_STYLES,
      crmIdDisabled: false,
      crm_id_rules: [
        ...required,
        ...manageLenghtError(20),
        ...getRegExp('space_not_allowed')
      ],
      ragione_sociale_rules: [...required, ...manageLenghtError(255)],
      c_description_rules: [...manageLenghtError(255)],
      geo_rules: [...getRegExp('numbers_dot_only'), ...getRegExp('space_not_allowed'), ...manageLenghtError(20)],
      zoom_rules: [...getRegExp('numbers_only')]
    };
  },
  computed: {
    ...mapState(['customer', 'disableTab', 'file_transfer']),
    splitBucketS3Path() {
      const res = this.CustomerForm.logo.split('/');

      return res[1];
    }
  },
  created() {
    this.file_transfer.downloadLink = '';

    if (this.customer.isModifingCustomer) {
      this.CustomerForm = this.$store.state.customerCRMID;
      this.crmIdDisabled = true;
      const params = {
        Bucket: S3_BUCKETS.customer(),
        Key: `${this.CustomerForm.logo}`
      };
      this.getItem(params);
    }
  },

  methods: {
    ...mapActions([
      'storeNewCustomer',
      'storeUpdatedCustomer',
      'storeCustomerCRMID',
      'switchTabState',
      'storeToastMessage',
      'storeTechUsers',
      'storeAppUsers',
      'storeCRMID',
      'setUpload',
      'storeLogoCustomer',
      'getItem',
      'storeCustomers'
    ]),
    checkForm() {
      return !(this.valid &&
        this.CustomerForm.crm_id !== '' &&
        this.CustomerForm.ragione_sociale !== '');
    },
    async submit() {
      this.overlay = true;

      if (!this.checkForm() && this.valid) {
        if (this.customer.isModifingCustomer) {
          await this.storeUpdatedCustomer(this.CustomerForm);
        } else {
          try {
            await this.storeNewCustomer(this.CustomerForm);
            this.crmIdDisabled = true;
            this.$store.state.disableTab = false;
            this.customer.isModifingCustomer = true;
          } catch {
            this.CustomerForm.crm_id = '';
            this.clear();
          }
        }

        await this.storeCustomerCRMID(this.CustomerForm);
        await this.storeCRMID(this.CustomerForm.crm_id);
        await this.storeTechUsers(this.CustomerForm.crm_id);
        await this.storeAppUsers(this.CustomerForm.crm_id);
        this.$emit('toParent', this.CustomerForm);
      } else {
        this.storeToastMessage({
          text: 'Please set required fields',
          type: 'warning'
        });
      }

      this.overlay = false;
    },
    clear() {
      this.CustomerForm.ragione_sociale = '';
      this.CustomerForm.c_description = '';
      this.CustomerForm.latitude = '';
      this.CustomerForm.longitude = '';
      this.CustomerForm.zoom = '';
      this.CustomerForm.map_style = '';
    },
    preview_image() {
      if (this.image) {
        const reader = new FileReader();

        reader.readAsDataURL(this.image);

        reader.onload = evt => {
          const img = new Image();

          img.onload = () => {
            this.seeImage = true;
            this.image.width = img.width;
            this.image.height = img.height;
            this.url = URL.createObjectURL(this.image);
            this.CustomerForm.logo = this.CustomerForm.crm_id + '/' + this.image.name;
          };

          img.src = evt.target.result;
        };

        reader.onerror = evt => {
          log.error(evt);
        };
      } else {
        this.seeImage = false;
      }
    },
    async upload_image() {
      this.overlay = true;

      if (this.image != null && this.CustomerForm.crm_id != '' && this.CustomerForm.crm_id != undefined && this.imageError === '') {
        const params = {
          Bucket: S3_BUCKETS.customer(),
          Key: `${this.CustomerForm.crm_id}/${this.image.name}`,
          Body: this.image
        };
        await this.setUpload(params);

        if (this.file_transfer.uploadState) {
          Object.assign(this.CustomerForm, {
            logo: `${this.CustomerForm.crm_id}/${this.image.name}`
          });
          await this.storeLogoCustomer(this.CustomerForm);
          this.url = '';
          this.file_transfer.downloadLink = '';
          const params = {
            Bucket: S3_BUCKETS.customer(),
            Key: `${this.CustomerForm.logo}`
          };
          this.getItem(params);
          this.seeImage = false;
        }
      }

      this.overlay = false;
    }
  }
};
</script>

<style scoped>
#customer-logo {
  object-fit: contain;
  object-position: left top;
  width: 180px;
  height: 90px;
}
</style>
